define("discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/conditional-loading-spinner", "discourse/components/cook-text", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template2, _conditionalLoadingSpinner, _cookText, _dButton, _dModal, _ajax, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModalDiffModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "diff", [_tracking.tracked]))();
    #diff = (() => (dt7948.i(this, "diff"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "suggestion", [_tracking.tracked], function () {
      return "";
    }))();
    #suggestion = (() => (dt7948.i(this, "suggestion"), void 0))();
    constructor() {
      super(...arguments);
      this.suggestChanges();
    }
    async suggestChanges() {
      this.loading = true;
      try {
        const suggestion1 = await (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
          method: "POST",
          data: {
            mode: this.args.model.mode,
            text: this.args.model.selectedText,
            custom_prompt: this.args.model.customPromptValue,
            force_default_locale: true
          }
        });
        this.diff = suggestion1.diff;
        this.suggestion = suggestion1.suggestions[0];
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      } finally {
        this.loading = false;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "suggestChanges", [_object.action]))();
    triggerConfirmChanges() {
      this.args.closeModal();
      if (this.suggestion) {
        this.args.model.toolbarEvent.replaceText(this.args.model.selectedText, this.suggestion);
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "triggerConfirmChanges", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="composer-ai-helper-modal"
          @title={{i18n "discourse_ai.ai_helper.context_menu.changes"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <ConditionalLoadingSpinner @condition={{this.loading}}>
              {{#if this.loading}}
                <div class="composer-ai-helper-modal__loading">
                  <CookText @rawText={{this.selectedText}} />
                </div>
              {{else}}
                {{#if this.diff}}
                  {{htmlSafe this.diff}}
                {{else}}
                  <div class="composer-ai-helper-modal__old-value">
                    {{@model.selectedText}}
                  </div>
    
                  <div class="composer-ai-helper-modal__new-value">
                    {{this.suggestion}}
                  </div>
                {{/if}}
              {{/if}}
            </ConditionalLoadingSpinner>
    
          </:body>
    
          <:footer>
            {{#if this.loading}}
              <DButton
                class="btn-primary"
                @label="discourse_ai.ai_helper.context_menu.loading"
                @disabled={{true}}
              />
            {{else}}
              <DButton
                class="btn-primary confirm"
                @action={{this.triggerConfirmChanges}}
                @label="discourse_ai.ai_helper.context_menu.confirm"
              />
              <DButton
                class="btn-flat discard"
                @action={{@closeModal}}
                @label="discourse_ai.ai_helper.context_menu.discard"
              />
              <DButton
                class="regenerate"
                @icon="arrows-rotate"
                @action={{this.suggestChanges}}
                @label="discourse_ai.ai_helper.context_menu.regen"
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "ivC7qach",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"composer-ai-helper-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.context_menu.changes\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"            \"],[10,0],[14,0,\"composer-ai-helper-modal__loading\"],[12],[1,\"\\n              \"],[8,[32,3],null,[[\"@rawText\"],[[30,0,[\"selectedText\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"diff\"]],[[[1,\"              \"],[1,[28,[32,4],[[30,0,[\"diff\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,0],[14,0,\"composer-ai-helper-modal__old-value\"],[12],[1,\"\\n                \"],[1,[30,2,[\"selectedText\"]]],[1,\"\\n              \"],[13],[1,\"\\n\\n              \"],[10,0],[14,0,\"composer-ai-helper-modal__new-value\"],[12],[1,\"\\n                \"],[1,[30,0,[\"suggestion\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\\n      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary\"]],[[\"@label\",\"@disabled\"],[\"discourse_ai.ai_helper.context_menu.loading\",true]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,5],[[24,0,\"btn-primary confirm\"]],[[\"@action\",\"@label\"],[[30,0,[\"triggerConfirmChanges\"]],\"discourse_ai.ai_helper.context_menu.confirm\"]],null],[1,\"\\n          \"],[8,[32,5],[[24,0,\"btn-flat discard\"]],[[\"@action\",\"@label\"],[[30,1],\"discourse_ai.ai_helper.context_menu.discard\"]],null],[1,\"\\n          \"],[8,[32,5],[[24,0,\"regenerate\"]],[[\"@icon\",\"@action\",\"@label\"],[\"arrows-rotate\",[30,0,[\"suggestChanges\"]],\"discourse_ai.ai_helper.context_menu.regen\"]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/diff-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _conditionalLoadingSpinner.default, _cookText.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ModalDiffModal;
});